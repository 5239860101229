.alert {
  width: max-content;
  background: #002880;
  padding: 1rem;
  border-radius: 4px;
  color: white;
  margin: 0 auto;
  margin-top: 20px;
}

.alert button {
  border-radius: 4px;
  display: inline-block;
  border: none;
  padding: 4px 8px;
  margin: 0;
  text-decoration: none;
  background: #FF015D;
  color: #ffffff;
  font-family: Poppins;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out,
  transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-left: 20px;
}